import {bool, func, instanceOf, string} from 'prop-types';
import Dialog from '@mui/material/Dialog';
import React, {useEffect} from 'react';
import {makeStyles} from '@theme/makeStyles';
import UserAvatar from '../User/UserAvatar';
import {Box, Grid, Skeleton, Theme, Tooltip} from '@mui/material';
import CloseIcon from '../../icons/subscribe/glyphs-close-small.svg';
import { useTranslation } from 'react-i18next';
import UserManager from '../../Provider/Controller/user/UserManager';
import SVGImage from './SVGImage';
import UsernameBadge from './UsernameBadge';
import SlideTransition from './SlideTransition';
import {useAppDispatch, useAppSelector} from '../../Redux/store';
import {selectUser} from '../../Redux/slices/usersSlice';
import {getFilePath} from '../../Utils/fileUtils';
import getUserById from '../../Redux/slices/usersSlice/thunks/getUserById';

interface UserModalProps {
    // The user instance or the ID
    user: UserManager|string;
    open: boolean;
    closeHandler: () => void;
    closedHandler?: () => void;
    children: JSX.Element[] | JSX.Element;
    className?: string;
    dialogClassName?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    avatar: {
        marginLeft: 0,
        width: '5.5rem',
        height: '5.5rem',
        border: '7px solid ' + theme.palette.background.paper,
        boxSizing: 'content-box',
        backgroundColor: theme.palette.background.paper
    },
    dialog: {
        borderRadius: 6,
        position: 'relative',
        overflow: 'visible',
        maxHeight: 'unset'
    },
    dialogAvatar: {
        position: 'absolute',
        // top: '-23%',
        top: '-4.3rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    cancelButton: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        position: 'relative',
        zIndex: 1
    },
    paperWidth: {
        marginLeft: '.5rem',
        marginRight: '.5rem',
        width: '100%'
    },
    dialogContainer: {
        padding: theme.spacing(1)
    },
    close: {
        cursor: 'pointer'
    },
    skeletonAvatar: {
        borderRadius: '100%',
        background: theme.palette.background.paper
    }
}));

const UserModal = (props: UserModalProps): JSX.Element => {
    const { classes, cx } = useStyles();

    const [entered, setEntered] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const {children, user: userProp, closeHandler, open, className, dialogClassName, closedHandler} = props;

    const dispatch = useAppDispatch();
    const userId = typeof userProp === 'string' ? userProp : (userProp && userProp.data && userProp.data.id);

    const guardedCloseHandler = () => {
        if (entered) {
            closeHandler();
            setEntered(false);
        }
    };

    const {t} = useTranslation('profile');

    const user = useAppSelector(s => selectUser(s.users, userId));

    const avatarPath = user && user.avatar && getFilePath(user.avatar, 'avatar', 'profileImage', 'md');
    const avatarUrl = user && (user.avatarUrlHighRes || user.avatarUrl || (avatarPath && avatarPath.url));

    useEffect(() => {
        if (!user && !loaded && userId) {
            dispatch(getUserById({userId}));
            setLoaded(true);
        }
    }, [user, loaded, userId]);

    return (
        <Dialog
            TransitionComponent={SlideTransition}
            open={open}
            onClose={guardedCloseHandler}
            aria-labelledby="BottomMenu"
            aria-describedby="Options"
            fullWidth={true}
            maxWidth={'sm'}
            classes={{root: classes.dialogContainer}}
            PaperProps={{
                classes: {
                    root: cx(classes.paperWidth, dialogClassName),
                    rounded: classes.dialog
                },
            }}
            TransitionProps={{
                onEntered: () => setEntered(true),
                onExited: closedHandler
            }}>
            <div className={className}>
                <div className={classes.dialogAvatar}>
                    <div aria-label='close dialog before go to user profile' onClick={guardedCloseHandler}>
                        {user ? (
                            <UserAvatar avatarUrl={[avatarUrl]} username={[user.username]} className={classes.avatar}/>
                        ) : (
                            <div className={cx(classes.avatar, classes.skeletonAvatar)}>
                                <Skeleton variant={'circular'} width={'100%'} height={'100%'} />
                            </div>
                        )}
                    </div>
                </div>

                <Grid container justifyContent={'flex-end'} className={classes.cancelButton}>
                    <Tooltip title={t('CANCEL')}>
                        <SVGImage svg={CloseIcon} width={10} height={10} removeMargin aria-label='close user modal' onClick={closeHandler} className={classes.close} />
                    </Tooltip>
                </Grid>

                <Box mt={2}>
                    {user ? (
                        <UsernameBadge aria-label={'close dialog before go to user profile'} onClick={guardedCloseHandler} typographyProps={{variant: 'h1'}} username={user.username} displayName={user.displayName} isInfluencer={user.influencer} justifyContent={'center'} />
                    ) : (
                        <Box display={'flex'} justifyContent={'center'}>
                            <Skeleton width={100}/>
                        </Box>
                    )}
                </Box>

                {children}
            </div>
        </Dialog>
    );
};

UserModal.propTypes = {
    username: instanceOf(UserManager),
    open: bool.isRequired,
    closeHandler: func.isRequired,
    className: string,
    dialogClassName: string
};

export default UserModal;
