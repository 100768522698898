import {FileType} from '@model/Interfaces/FileInterface';
import {FilePathTypeOptimizationMap, ShortPathTypeMap} from '@model/Interfaces/FilePathInterface';
import {CDN_OPT_ENABLED, CURRENT_FILE_VERSION} from '../Constants';
import File from '@vtsfans/sugarfans.js/build/main/lib/Model/File/File';
import FilePath from '@vtsfans/sugarfans.js/build/main/lib/Model/File/FilePath';

export const getFilePath = <FType extends FileType,PType extends ShortPathTypeMap[FType]>(
    file: File,
    fileType: FType,
    pathType: PType,
    ...[resolution, strict]: (FilePathTypeOptimizationMap[PType] extends never ? [resolution?: undefined, strict?: undefined]:[resolution: FilePathTypeOptimizationMap[PType], strict?: boolean])
): FilePath|null => {

    // if (fileType !== this._type) {
    // console.trace({fileType, pathType, resolution}, this);

    //     throw new Error('Attempted to get path that this file does not contain');
    // }

    const path = file.paths.find(p => p.type === pathType && (!resolution || p.size === resolution) && p.version === CURRENT_FILE_VERSION);

    if (strict !== false || path) {
        return path;
    }

    return file.paths.find(p => p.type === pathType && p.version === CURRENT_FILE_VERSION);
};


export const formatCdnUrl = (url: string, opts: {width?: number, height?: number, blur?: number}): string => {
    if (!url) {
        return url;
    }

    if (!CDN_OPT_ENABLED()) {
        return url;
    }

    const params = Object.entries(opts).reduce((queryString, opt) => {
        const [key, value] = opt;

        if (!value) {
            return queryString;
        }

        return `${queryString}&${key}=${value}`;
    }, '');

    return `${url}${params}&format=auto`;
};