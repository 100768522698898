import DomainEvent, { DomainEventData } from '../../DomainEvent';
import { SugarFansEvent } from '../../EventBus';

export interface DrmPlayerInitializedEventData extends DomainEventData {
    fingerprint: string
}

class DrmPlayerInitializedEvent extends DomainEvent {
    constructor(fingerprint: string) {
        super({fingerprint});
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.file.drm_player_init';
    }
}

export default DrmPlayerInitializedEvent;
