import LoadingScreen from '@components/Utils/LoadingScreen';
import Tooltip from '@components/Utils/Tooltip';
import { Box, Typography, Grid, Button } from '@mui/material';
import { ValidPaymentContents } from '@provider/Data/Interfaces/ChargeDataProviderInterface';
import { TaxType } from '@provider/Data/Model/Interfaces/InitialConfigInterface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTax from 'src/Hooks/Utils/useTax';
import { useMoneyWallet } from 'src/Services/SWR';

export type PaymentButtonLoading = 'wallet'|'card';

interface PaymentButtonProps {
    contentType: ValidPaymentContents;
    amount: number;
    optionHandler: (walletId: string) => void;
    disabled?: boolean;
    loading?: PaymentButtonLoading;
    showSum?: boolean;
}

const contentTypeToTaxType = (contentType: ValidPaymentContents): TaxType => {
    if (contentType === 'chat_file' || contentType === 'post') {
        return 'ppc';
    }

    if (contentType === 'wallet_transaction') {
        return 'wallet';
    }

    return contentType as TaxType;
};

const PaymentButton = (props: PaymentButtonProps): JSX.Element => {
    const {contentType, amount, optionHandler, disabled = false, loading, showSum = false} = props;

    const [tax] = useTax(contentTypeToTaxType(contentType));
    const { t } = useTranslation('paymentMethods');

    const {data: wallet} = useMoneyWallet();
    const hasBalance = wallet && wallet.hasEnoughBalance(amount);
    const showWallet = wallet && wallet.amount > 0;
    const buttonDisabled = !!loading || disabled;

    return <>
        {showWallet && (
            <Box mb={2}>
                <Typography variant='h3'>
                    {t('CURRENT_WALLET_BALANCE')}: <Box color='primary.main' component='span'>${(wallet.amount/100).toFixed(2)}</Box>
                </Typography>
            </Box>
        )}
        <Grid container spacing={1}>
            <Grid item xs={12} sm={showWallet ? 6 : 12}>
                <Button variant={'contained'} fullWidth aria-label='payment method modal pay with card' onClick={() => optionHandler(null)} disabled={buttonDisabled}>
                    {loading === 'card' ? <LoadingScreen size={25}/> : t('PAY_WITH_CARD', {amount: tax(amount/100, showSum)})}
                </Button>
            </Grid>
            {showWallet && (
                <Grid item xs={12} sm={6}>
                    <Tooltip title={t('NOT_ENOUGH_BALANCE')} disabled={hasBalance}>
                        <Button variant={'contained'} fullWidth aria-label='payment method modal pay with wallet' onClick={() => optionHandler(wallet.id)} disabled={!wallet || !hasBalance || buttonDisabled}>
                            {loading === 'wallet' ? <LoadingScreen size={25}/> : t('PAY_WITH_WALLET')}
                        </Button>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    </>;
};

export default PaymentButton;