import { Container, Dialog, Grid, IconButton, Theme, Typography, TypographyProps } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_DOMAIN } from '../../Constants';
import { useAppContext } from '../../Context/AppContext';
import { DialogProps, useDialog, withDialog } from '../../Context/ModalContext';
import { ValidPaymentContents } from '../../Provider/Data/Interfaces/ChargeDataProviderInterface';
import { ChargeUpdatedEventData } from '../../EventBus/Event/Charge/ChargeUpdatedEvent';
import EventBus, { useSingleEventBus } from '../../EventBus/EventBus';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LoadingScreen from '../Utils/LoadingScreen';
import {useCards, useMoneyWallet} from '../../Services/SWR';
import {getAffiliateId, guestAffiliationId} from '../../Hooks/Logic/promoterLogic';
import {getDrmFingerprint} from '@components/VideoPlayer/DRM/drmUtils';
import DrmPlayerInitializedEvent from '../../EventBus/Event/File/DrmPlayerInitializedEvent';

const useStyles = makeStyles()((theme: Theme) => ({
    iframe: {
        width: '100%',
        flexGrow: 1,
        border: 'none'
    },
    paperWidth: {
        marginLeft: 0,
        marginRight: 0,
        width: '100%'
    },
    dialogContainer: {
        padding: theme.spacing(1)
    },
    dialog: {
        borderRadius: 6,
        position: 'relative',
        overflow: 'visible',
        height: '90vh',
        maxHeight: 1000,
    },
    divContainer: {
        textAlign: 'center'
    },
    container: {
        height: '100%',
        padding: 0,
        position: 'relative'
    },
    containerProcess: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingScreen: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    iconButton: {
        position: 'absolute',
        transform: 'translate(30%, -100%)',
        top: 0,
        right: 0,
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: 'white',
        [theme.breakpoints.down('md')]: {
            transform: 'translate(30%, -100%)'
        }
    },
    textAutoRenew: {
        padding: theme.spacing(1),
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: '#333'
    }
}));

// Extract all the business logic into this hook to keep the Component itself mostly focused on UI
const useChargeModalLogic = (reject,id, is3D): [() => void, boolean, boolean] => {
    const { controllers, config, notifier } = useAppContext();
    const { t } = useTranslation('dimoco');

    const initialized = Boolean(id);
    const [iframeLoaded, setIframeLoaded] = React.useState<boolean>(null);
    const [done3D, setDone3D] = React.useState<boolean>(null);
    const [timeout, setProviderTimeout] = React.useState<NodeJS.Timeout>(null);

    const rejectAndNotify = (msg: string) => {
        reject(msg);
        notifier.error(msg);
    };

    const setupTimeout = () => {
        const providerTimeout = setTimeout(() => {
            controllers.chargeController.changeState(id, 'error', 'PROVIDER_TIMEOUT').catch(e => console.warn(e)).finally(() => {
                rejectAndNotify(t('ERROR'));
            });
        }, config.chargeProcessingTimeout * 1000);

        setProviderTimeout(providerTimeout);
    };

    // On initializing, set states to default values
    React.useEffect(() => {
        if (initialized) {
            setIframeLoaded(!is3D);
            setDone3D(!is3D);
            if (!is3D && !timeout) { // if we're not going to go through 3d on mount, setup timeout
                setupTimeout();
            }
        }
    }, [initialized]);

    // Clean up timeout
    React.useEffect(() => {
        return () => {
            // Clear timeout on unmount
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [timeout]);

    const onLoad = React.useCallback(() => {
        const iframe = document.getElementById('charge-iframe') as HTMLIFrameElement;
        try {
            if (iframe.contentWindow.location.hostname === APP_DOMAIN() && iframeLoaded) { // we're done
                const status = iframe.contentWindow.location.search.split('chargeCallback=')[1];
                if (status == null || status === 'error') {
                    rejectAndNotify(t('ERROR'));
                } else if (status === 'cancel') {
                    rejectAndNotify(t('CANCELLED'));
                } else {
                    controllers.chargeController.changeState(id, 'processing').catch(e => console.warn(e)).finally(() => {
                        setDone3D(true);
                        setupTimeout();
                    });
                }
            } else if (!iframeLoaded) { // our own custom 3d page is loading
                setIframeLoaded(true);
            }
        } catch (e) {
            // Can't access origin
            setIframeLoaded(true);
        }
    }, [setIframeLoaded, iframeLoaded, setDone3D, setProviderTimeout]);

    return [onLoad, done3D, iframeLoaded];
};

type ChargeModalStyleData = {
    autoRenewText?: string;
    autoRenewTextProps?: Partial<TypographyProps>
}

export interface ChargeModalData extends ChargeModalStyleData {
    url3D: string;
    opened: () => void;
    id: string;
    reject: (msg: string) => void;
}

const ChargeModal = (props: DialogProps<ChargeModalData>): JSX.Element => {
    const { data, destroyHandler, open, closeHandler } = props;
    const { url3D, opened, id, reject, autoRenewText, autoRenewTextProps } = data;

    const { t } = useTranslation('dimoco');
    const {classes, cx} = useStyles();

    const [onLoad, done3D, iframeLoaded] = useChargeModalLogic(reject, id, url3D != null);

    return (
        <Dialog
            open={open}
            classes={{ root: classes.dialogContainer }}
            fullWidth
            maxWidth={'md'}
            PaperProps={{
                classes: {
                    root: classes.paperWidth,
                    rounded: classes.dialog
                },
                style:done3D? undefined: { backgroundColor: 'white' }
            }}
            TransitionProps={{
                onEntered: opened,

                onExited: () => {
                    destroyHandler();
                    opened();
                }
            }}>
            <Container className={cx(classes.container, done3D ? classes.containerProcess : '')}>
                <IconButton className={classes.iconButton} size='small' aria-label='close charge modal' onClick={() => { closeHandler(); reject('closed'); }}>
                    <CloseRoundedIcon />
                </IconButton>
                {!done3D && <Grid container direction='column' style={{ visibility: iframeLoaded ? 'visible' : 'hidden', position: 'relative', height: '100%', paddingTop: 6 }}>
                    <iframe id='charge-iframe' onLoad={onLoad} className={classes.iframe} src={url3D} title='payment gateway' />
                    {autoRenewText && <Typography variant='h3' align={'center'} className={classes.textAutoRenew} {...autoRenewTextProps}>{autoRenewText}</Typography>}
                </Grid>}
                {!iframeLoaded && <LoadingScreen size={70} className={classes.loadingScreen} />}
                {done3D &&
                <div className={classes.divContainer}>
                    <Typography variant='h2' color={'textPrimary'}>{t('PROCESSING')}</Typography>

                    <Typography variant='h2' color={'textPrimary'}>{t('DO_NOT_CLOSE')}</Typography>

                </div>
                }
            </Container>
        </Dialog>
    );
};


// The logic for opening the charge modal
type UseChargeCallback<T,> = (contentType: ValidPaymentContents, amount: number, contentUserId?: string, cardId?:string, walletId?: string, params?: T, styleData?: ChargeModalStyleData) => Promise<string>;

/**
 * The validation promise is different for every charge, it's a request that determines whether a payment is valid
 * It must be done before actually beginning to execute the payment
 *
 * @param validationPromise The promise to use for validation
 * @param walletEnabled Whether payment with wallet is enabled
 * @param opts Optional parameters for rendering charge modal
 *
 * @returns
 */
export const useCharge = <T,>(validationPromise: (params?: T) => Promise<string>, contentTypeId?: string): UseChargeCallback<T> => {
    const { controllers, notifier } = useAppContext();
    const [openChargeModal, closeChargeModal] = useDialog<Partial<ChargeModalData>>('ChargeModal');
    const { t } = useTranslation('dimoco');
    const [promiseProps, setPromiseProps] = React.useState<{resolve:(() => void); reject: (msg: string) => void }>(null);

    const {refresh: refreshCards} = useCards(false);
    const {refresh: refreshWallet, data: wallet, loading: walletLoading} = useMoneyWallet();

    // We need contentTypeId because other elements can subscribe to the event and cause errors in the logic
    useSingleEventBus<ChargeUpdatedEventData>('charge_updated', `charge_updated_modal-${contentTypeId}`, e => {
        // no need to check the event id for the same reason as above
        // The timeout is needed to simulate a processing state with a wallet payment. It is so fast that sometimes the modal does not close correctly
        setTimeout(() => handleChargeState(e.state), 2000);
    }, [promiseProps]);

    const handleChargeState = (state) => {
        if (!promiseProps) {
            return;
        }
        if (state === 'error') {
            promiseProps.reject(t('ERROR'));
            notifier.error(t('ERROR'));
        } else if (state === 'valid') {
            promiseProps.resolve();
        }
    };

    const getPromoterId = (type: ValidPaymentContents, contentUserId?: string): string|null => {
        // Only active for subscriptions
        if (type !== 'subscription') {
            return null;
        }

        const guestAffId = guestAffiliationId();
        const affId = getAffiliateId(contentUserId);

        // Guest affiliation takes preference (type 1)
        if (guestAffId) {
            return guestAffId;
        }

        // Only valid if profile affiliation is the same as the user you are subscribing to
        if (affId && contentUserId) {
            return affId;
        }

        return null;
    };

    return React.useCallback<UseChargeCallback<T>>((contentType: ValidPaymentContents, amount: number, contentUserId?: string, cardId?: string, walletId?: string, params?: T, styleData: ChargeModalStyleData = {}): Promise<string> => {
        let contentId: string;
        let opened;

        const promoterId = getPromoterId(contentType, contentUserId);

        // This promise is simply resolved when the modal opened, makes sure that it can be closed after all is said and done
        const modalOpenedPromise = new Promise<void>(resolve => {
            opened = resolve;
        });

        // This promise is what will be returned from the callback and make the entire charge logic transparent to the rest of the app
        const chargeSuccessPromise = new Promise<void>((resolve, reject) => {
            // Pass resolution functions to event bus
            setPromiseProps({resolve, reject});

            // This set of promises are in charge of creating the charge, validating it and opening the modal
            validationPromise(params)
                .then(id => {
                    contentId = id;

                    return controllers.chargeController.create(contentType, id, cardId, null, walletId, promoterId);
                })
                .then(chargeInfo => {
                    openChargeModal({ url3D: chargeInfo.url3D, id: chargeInfo.chargeId, opened, reject, ...styleData });
                })
                .then(() => getDrmFingerprint())
                .then(fingerprint => EventBus.dispatch(new DrmPlayerInitializedEvent(fingerprint)))
                .catch(e => reject(e));
        });

        return Promise.all([modalOpenedPromise, chargeSuccessPromise])
            .finally(() => {
                closeChargeModal();
                refreshCards();
                refreshWallet();
            }).then(() => contentId);
    }
    , [validationPromise, promiseProps, wallet, walletLoading]);
};

export default withDialog(ChargeModal, 'ChargeModal');
