import {ClickAwayListener} from '@mui/material';
import React, {useState} from 'react';
import TooltipMui, { TooltipProps } from '@mui/material/Tooltip';

interface ElementProps extends TooltipProps {
    title: string | JSX.Element;
    children: JSX.Element;
    disabled: boolean;
    className?: string;
}

const Tooltip = (props: ElementProps): JSX.Element => {
    const {title, children, disabled, className, ...rest} = props;
    const [open, setOpen] = useState(false);

    if (disabled) {
        return children;
    }
    
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <span aria-label='open tooltip' onClick={() => setOpen(true)} className={className ? className : ''}>
                <TooltipMui
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={() => setOpen(false)}
                    open={open}
                    arrow
                    title={title}
                    {...rest}
                >
                    {children}
                </TooltipMui>
            </span>
        </ClickAwayListener>
    );
};

Tooltip.defaultProps = {
    disabled: false,
};

export default Tooltip;